/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const CircleIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        max-width: 100%;
        height: 15px;
        width: 11px;
        margin-bottom: 4px;
        vertical-align: middle;
      `}
    >
      <path
        d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
        fill="#8BADA3"
        stroke={color}
      />
    </svg>
  );
};

export default CircleIcon;

CircleIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
