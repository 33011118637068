import React from 'react';
import styled from '@emotion/styled/macro';

import {
  FacebookLogo,
  InstagramLogo,
  PhoneLogo,
  LinkedInLogo,
} from 'components/svgs';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const ContactUs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  justify-self: end;

  @media screen and (max-width: 820px) {
    order: 2;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;

    div:first-of-type {
      flex-basis: 40%;

      p {
        margin-inline-start: 0;
      }
    }
  }
`;
const CallUs = styled.div`
  display: flex;
  text-align: end;
  font-size: 31px;
  font-weight: bold;
  align-items: center;
  p {
    margin: 0 10px;
  }
`;
const SocialMedia = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  @media screen and (max-width: 820px) {
    flex-grow: 1;
    margin-bottom: 0;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const ContactUsFooter = () => {
  const { language } = i18next;
  const {t} = useTranslation('footer');
  return (
    <ContactUs>
      <p style={{fontSize:'18px', margin:"8px 8px", textAlign:'center'}} >{t('info.contactus')}</p>
      <a href="tel:16128" target="_blank">
        <CallUs lang={language}>
          <PhoneLogo />
          <p>16128</p>
        </CallUs>
      </a>
      <SocialMedia>
        <a href="https://www.facebook.com/bluebuseg/" target="_blank" rel="noopener">
          <FacebookLogo />
        </a>
        <a
          href="https://instagram.com/bluebuseg?igshid=5jgn5n0rkilg"
          target="_blank"
          rel="noopener"
        >
          <InstagramLogo />
        </a>
        <a href="https://www.linkedin.com/company/bluebuseg/" target="_blank" rel="noopener">
          <LinkedInLogo />
        </a>
      </SocialMedia>
    </ContactUs>
  );
};

export default ContactUsFooter;
