/* This is the cancel ticket component. It is used to cancel a ticket and can be reached when
the user clicks on cancel ticket from my trips on any ticket that's not in the past.  */

import React from 'react';
import styled from '@emotion/styled/macro';
import {colors, notify} from 'utilities';
import {SingleTicket} from 'components';
import {Button} from 'components/button';
import {useHistory, Redirect} from 'react-router-dom';
import {useMutation} from '@apollo/react-hooks';
import {cancelOrderedTicket} from 'services';
import firebase from 'firebase';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import {useAuthDataContext} from 'utilities';
import {FirebaseAnalytics} from 'helperFunctions/FirebaseAnalytics';
import {Spin} from "antd";

const CancelTripContainer = styled.section`
  width: 90%;
  max-width: 1000px;
  margin: 40px auto;

  h1 {
    text-align: center;
    color: ${colors.primaryColor};
    font-size: 32px;
    font-weight: bolder;
    margin: 0;
    margin-bottom: 40px;
  }

  h2 {
    color: ${colors.primaryColor};
    font-size: 22px;
    font-weight: bolder;
  }
`;

const TermsList = styled.ul`
  padding-inline-start: 14px;
  margin-top: 30px;
  margin-bottom: 50px;
  li {
    line-height: 22px;
    color: ${colors.primaryColor};
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: 14px;
  }
`;

const CancelTrip = () => {
    const {customer} = useAuthDataContext();
    const {phone, email} = customer || {
        id: null,
    };
    const {t} = useTranslation('cancelTrip');
    const {language} = i18next;

    const history = useHistory();
    const ticket = history.location.state && history.location.state.ticket;
    const seat = history.location.state && history.location.state.seat;

    const [cancelTicket, {submitting, loading}] = useMutation(cancelOrderedTicket, {
        variables: {
            ticketId: seat && seat.ticketId,
        },
    });

    const submitCancel = () => {
        cancelTicket()
            .then(res => {
                const {
                    data: {
                        cancelOrderedTicket: {status, message, message_ar},
                    },
                } = res;

                if (status) {
                    notify('success', language === 'en' ? message : message_ar);
                    history.replace('/my-trips#Upcoming', null);
                } else {
                    notify('error', language === 'en' ? message : message_ar);
                }
            })
            .catch(err => {
            });
    };

    if (!ticket || !seat) return <Redirect to="/"/>;

    return (
        <CancelTripContainer>
            <h1>{t('header')}</h1>
            <h2>{t('subHeader')}</h2>
            <TermsList>
                <li>{t('policyList.policy1')}</li>
                <li>{t('policyList.policy2')}</li>
                <li>{t('policyList.policy3')}</li>
                <li>{t('policyList.policy4')}</li>
            </TermsList>

            <SingleTicket ticketDetails={ticket} seat={seat}/>
            <Spin spinning={loading}>
            <Button danger disabled={submitting} onClick={() => {
                firebase.analytics().logEvent('cancel_ticket', {
                    source: `${window.location.href}`,
                    phoneNumber: `${phone}`,
                    email: `${email}`,
                })
                submitCancel()
            }}>
                    {t('cancelButton')}

            </Button>
            </Spin>
        </CancelTripContainer>
    );
};

export default CancelTrip;
