import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import firebase from 'firebase';
import { Link, useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import i18next from 'i18next';
import EnglishLogo from '../images/logo.png';
import ArabicLogo from 'scenes/home/images/arabicLogo.png';
import BurgerIcon from '../images/burgerIcon.png';
import CloseIcon from '../images/closeButton.png';
import NotificationIcon from '../images/notification.png';
import TicketIcon from '../images/ticket.png';
import UserIcon from '../images/user.png';
import { Button } from 'components/button';
import { colors, Container, notify } from 'utilities';
import { useAuthDataContext } from 'utilities/auth-context';
import { useMutation, useQuery } from '@apollo/react-hooks';
import GET_CUSTOMER_NOTIFICATIONS from 'services/notifications/get_customer_notifications';
import { FirebaseAnalytics } from 'helperFunctions/FirebaseAnalytics';
import ArabicIcon from 'components/header/images/arabic.png';
import EnglishIcon from 'components/header/images/english.png';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { MARK_READ_NOTIFICATION } from "../../../services/notifications";
import Footer from 'components/footer';
import {PopUp} from './index' 

const { Item } = Menu;

const MainHeader = styled.div`
${Container};
background-color: ${colors.primaryColor};
box-shadow: -6px -5px 39px 11px;
display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 12px;

  @media screen and (min-width: 1025px) {
    input[type='checkbox'],
    input[type='checkbox'] + svg {
      display: none;
    }
  }
  @media screen and (max-width: 1025px) {
    padding: 0;
  }

`;

const LogoWrapper = styled.div`
  margin: 8px;
  border-radius: 10px;
  width: ${isMobile ? '100px' : '190px'};
  align-self: center;
`;

const LinksWrapper = styled.nav``;

const UserActionsWatpper = styled.nav`
  span {
    margin-inline-start: 10px;
  }
  ul {
    justify-content: center;
    align-items: center;
  }
  ul li {
    margin-left: 5px;
    font-size: 10px;
    a {
      margin-left: 0;
      font-size: 13px;
    }
  }
  @media screen and (max-width: 1025px) {
    ul {
      flex-direction: column-reverse;
      text-align: start;
    }

    padding-bottom: 0;
  }
`;

const BurgerButton = styled(Button)`
  @media screen and (min-width: 860px) {
    display: none;
  }
`;

const TimeAgo = styled.p`
  color: #979797;
  font-size: 12px;
  float: left;
  font-weight: lighter;
`;

const NotificationContent = styled.p`
  max-width: 270px;
  word-wrap: break-word;
  white-space: break-spaces;
  font-weight: lighter;
  font-size: 13px;
  text-align: start;
  font-family: sans-serif;
`;

const NotificationTitle = styled.strong`
  display: block;
  word-wrap: break-word;
  white-space: break-spaces;
  font-size: 13px;
  font-family: 'FreightSans Pro';
`;

const Notify = styled.div`
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
`;
const ChangeLanguageButton = styled.button`
  font-family: 'FreightSans Pro';
  background: transparent;
  border: 0;
  color: white;
  cursor: pointer;
  &:focus {
    border: 0;
    outline-color: transparent;
  }
`;
const LanguageIcon = styled.img`
  width: ${isMobile ? '30px' : '33px'};
  height: 33px;
`;
const LanguageHeader = styled.h3`
    color: ${props => props.color};
`;

const UlGeneralStyle = styled.div`
  ul {
    display: flex;
    list-style: none;
  }
  ul li {
    display: flex;
    align-items: center;
    height: 60px;
    font-weight: 500;
    font-size: ${props => props.lang === 'en' ? '18px' : '15px'};
    padding: 24px 16px 8px 16px;
  }
  ul li a {
    color: ${props => props.color};
  }
  @media screen and (max-width: 1025px) {
    display: ${props => props.isMenuOpen ? 'block' : 'none'};
   ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 74px;
    left: ${props => props.lang === 'ar' ? '0' : null};
    right: ${props => props.lang === 'ar' ? null : '0'};
    z-index: 999;
    width: 111%;
    background-color: ${colors.primaryColor};
   }
  }
`;
const SubHeaderList = styled.div`
  ul {
    display: flex;
    list-style: none;
  }
  ul li {
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 14px;
    font-weight: 600;
    padding: ${isMobile ? '18px 0 0 0' : props => props.lang === 'en' ? '24px 8px 3px 6px' : '24px 16px 12px 6px'};
    position: relative;
  } 
  ul li a {
    color: ${props => props.color};
  }
  @media screen and (max-width: 1025px) {
    background-color: ${colors.primaryColor};
    ul li {
      font-size: 10px;
      font-weight: ${isMobile ? 'bold' : null};
      flex: 1;
      padding-inline-start: 0;

    }
  }
`;
const ListMenu = styled.div`
  background-color: ${colors.primaryColor};
  position: absolute;
  top: 74px;
  left: ${props => props.lang === 'ar' ? '0' : null};
  right: ${props => props.lang === 'ar' ? null : '0'};
  width: 100%;
  height: 370px;
  display: ${props => props.isMenuOpen ? 'block' : 'none'};
`;
const BurgerIconContainer = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }
  width: 28px;
  height: 28px;
`;
const SubheaderIcons = styled.img`
  width: 20px;
  height: 20px;
  margin: ${props => props.lang === 'en' ? '5px 7px 7px 0px' : '6px 0 8px 10px'};  
`;
const LogoImage = styled.img`
  border-radius: 5px;
`;
const ChangeLanguageContainer = styled.div`
  margin-top: ${isMobile ? '16px' : props => props.lang === 'en' ? '20px' : '12px'};
  justify-content: center;
  align-content: center;
  display: flex;
`;
const NotificationStaticText = styled.span`
  color: ${props => props.color};
`;
const NotificationNumber = styled.div`
  background-color: red;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${props => props.lang === 'ar' ? '15px' : '19px'};
  right: ${props => props.lang === 'ar' ? '7px' : '97px'} ;
  font-family: 'FreightSans Pro';
  padding: ${props => props.lang === 'ar' ? '0 6px 0 0' : '0 0 0 6px'} ;
  font-size: 14px;

  @media screen and (max-width: 1025px) {
    background-color: red;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    top: ${props => props.lang === 'ar' ? '21px' : '19px'};
    right: ${props => props.lang === 'ar' ? '7px' : '14px'} ;
    font-size: 11px;
    padding: ${props => props.lang === 'ar' ? '0 6px 0 0' : '0 0 0 6px'} ;
  }
`;

const NotificationsMenu = notificationsData => {
  const { language, } = i18next;

  //   return x.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, c => c.charCodeAt(0) & 0xf); window.location.href
  return (
    <Menu>
      {notificationsData &&
        notificationsData.getCustomerNotifications.data.map(notification => (
          <Item key={notification.id}>
            <Notify>
              <NotificationTitle>
                {notification[language === 'en' ? 'title_en' : 'title_ar']}
              </NotificationTitle>
              <TimeAgo>
                {moment(notification.created_at)
                  .locale(language === 'en' ? 'en-SG' : 'ar')
                  .fromNow()}
              </TimeAgo>
            </Notify>
            <NotificationContent>
              {notification[language === 'en' ? 'message_en' : 'message_ar']}
            </NotificationContent>
          </Item>
        ))}
    </Menu>
  );
};

const ActionMenu = () => {
  const { token, onLogout } = useAuthDataContext();
  const history = useHistory();
  const { t } = useTranslation('header');
  return !token ? (
    <Menu>
      <Item>
        <Link to="/login">
          <span>{t('mainHeader.signIn')}</span>
        </Link>
      </Item>
      <Item>
        <Link to="/register">
          <span>{t('mainHeader.register')}</span>
        </Link>
      </Item>
    </Menu>
  ) : (
    <Menu>
      <Item>
        <Link to="/profile"
        onClick={()=>{
          window.webengage.track('Profile Clicked');
        }}
        >
          <span>{t('mainHeader.profile')}</span>
        </Link>
      </Item>
      <Item
        onClick={async () => {
          await onLogout();
          history.push('/');
        }}
      >
        <Link to="#">
          <span>{t('mainHeader.signOut')}</span>
        </Link>
      </Item>
    </Menu>
  );
};

const MainHeaderWrapper = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { language } = i18next;
  const { t } = useTranslation('header');
  const appName = process.env.REACT_APP_NAME
  const { token, customer } = useAuthDataContext();
  const { id: customerId, email, phone: mobile } = customer || {
    id: null,
  };
  const { data: notificationsData, refetch: refetchNotification } = useQuery(GET_CUSTOMER_NOTIFICATIONS, {
    skip: !token,
  });
  const [mark_read_notification] = useMutation(MARK_READ_NOTIFICATION)
  const { color } = props;
  const handelLogoImage = language === 'ar' ? EnglishLogo : EnglishLogo;
  const [unReaded, setUnReaded] = useState(false)
  const [alarmColor, setAlarmColor] = useState(color);
  const [unreadedNotification, setUnreadedNotification] = useState(null);
  useEffect(() => {
    if (notificationsData) {
      let has_un_read = false;
      let readedNotification = [];
      notificationsData.getCustomerNotifications.data.map(notification => {
        if (notification.pivot.is_read == 0) {
          readedNotification.push(notification);
          has_un_read = true
        }
      })
      if (has_un_read) {
        setAlarmColor('red');
        setUnreadedNotification(readedNotification.length);
      }
      else {
        setAlarmColor(color);
        setUnreadedNotification(null);
      }
      setUnReaded(has_un_read)
    }
  }, [notificationsData])
  const openMenuWithBurgerList = () => {
    setIsMenuOpen(!isMenuOpen);
  }
  const switchLanguage = (lang) => {
    i18next.changeLanguage(lang === 'ar' ? 'en' : 'ar');
    localStorage.setItem('lang',lang === 'ar' ? 'en' : 'ar')
  };

  //Webengage lanhuage attribute

  useEffect(() => {
    window.webengage.user.setAttribute(
      'Language',
      language === 'en' ? 'English' : 'Arabic'
    );
  }, language);
  return (
    <MainHeader>
      {/* <PopUp/> */}
      <LogoWrapper lang={language}>
        <Link
          onClick={() => {
            setIsMenuOpen(false);
          }}
          to="/"
        >
          <LogoImage
            src={handelLogoImage}
            alt={i18next.language === 'en' ? `${appName} logo` : `لوجو ${appName}`}
          />
        </Link>
      </LogoWrapper>
      <UlGeneralStyle color={color} lang={language} isMenuOpen={isMenuOpen}>
        <ul>
          <li>
            <Link
              onClick={() => {
                window.webengage.track('Category Viewed ', {
                  'Category Name': `Home`,
                });
              }}
              to="/"
            >
              {t('mainHeader.bookTrip')}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                window.webengage.track('Order A Private Bus Clicked');
                window.webengage.track('Category Viewed ', {
                  'Category Name': `Private Bus`,
                });
              }}
              to="/Private-bus"
            >
              {t('mainHeader.privatebus')}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
                window.webengage.track('Category Viewed ', {
                  'Category Name': `Destinations`,
                });
              }}
              to="/destinations"
            >
              {t('mainHeader.destinations')}
            </Link>
          </li>
          <li>
            <Link
              to="/stations"
              onClick={() => {
                setIsMenuOpen(false);
                firebase.analytics().logEvent('station', {
                  source: `${window.location.href}`,
                  phoneNumber: `${mobile}`,
                  email: `${email}`,
                });
                window.webengage.track('Category Viewed ', {
                  'Category Name': `Station`,
                });
              }}
            >
              {t('mainHeader.stations')}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
                window.webengage.track('Category Viewed ', {
                  'Category Name': `Bus Classes`,
                });
              }}
              to="/bus-classes"
            >
              {t('mainHeader.busses')}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
                window.webengage.track('Category Viewed ', {
                  'Category Name': `About TripTech`,
                });
              }}
              to="/about"
            >
              {t('subHeader.aboutBlueBus', {name:appName})}
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setIsMenuOpen(false);
                window.webengage.track('Category Viewed ', {
                  'Category Name': `Faqs`,
                });
              }}
              to="/faqs"
            >
              {t('subHeader.faqs')}
            </Link>
          </li>
        </ul>
      </UlGeneralStyle>
      <SubHeaderList color={color} lang={language}>
        <ul>
          <ChangeLanguageContainer lang={language}>
            <ChangeLanguageButton
              onClick={() => {
                setIsMenuOpen(false);
                switchLanguage(language);
              }}
            >
              <LanguageIcon
                src={i18next.language === 'en' ? ArabicIcon : EnglishIcon}
                alt="Change Language"
              />
            </ChangeLanguageButton>
          </ChangeLanguageContainer>
          {token && (
            <li>
              <Dropdown
                overlay={NotificationsMenu(notificationsData)}
                placement="bottomCenter"
                overlayClassName="notifications-menu"
                trigger={['click']}
                onClick={e => {
                  if (unReaded)
                    mark_read_notification().then(() => {
                      setAlarmColor(color);
                    });
                  refetchNotification();
                }}
              >
                <a className="ant-dropdown-link">
                  <SubheaderIcons
                    lang={language}
                    src={NotificationIcon}
                    alt="NotificationIcon"
                  />
                  <NotificationStaticText color={unReaded && alarmColor}>
                    {!isMobile ? t('mainHeader.notifications') : null}
                  </NotificationStaticText>
                  {unReaded && (
                    <NotificationNumber lang={language}>
                      <span>{unreadedNotification}</span>
                    </NotificationNumber>
                  )}
                </a>
              </Dropdown>
            </li>
          )}

          {token && (
            <li>
              <Link
                onClick={() => {
                  setIsMenuOpen(false);
                }}
                to="/my-trips"
              >
                <SubheaderIcons
                  lang={language}
                  src={TicketIcon}
                  alt="TicketIcon"
                />
                {!isMobile ? t('mainHeader.myTrips') : null}
              </Link>
            </li>
          )}

          <li>
            <Dropdown
              trigger={['click']}
              overlay={ActionMenu()}
              placement="bottomCenter"
            >
              <a className="ant-dropdown-link" href="login">
                <SubheaderIcons lang={language} src={UserIcon} alt="UserIcon" />
              </a>
            </Dropdown>
          </li>

          <li>
            <BurgerIconContainer>
              <a onClick={openMenuWithBurgerList}>
                <img
                  src={isMenuOpen ? CloseIcon : BurgerIcon}
                  alt="burger-icon"
                />
              </a>
            </BurgerIconContainer>
            <ListMenu lang={language} isMenuOpen={isMenuOpen}></ListMenu>
          </li>
        </ul>
      </SubHeaderList>
    </MainHeader>
  );
};

export default MainHeaderWrapper;