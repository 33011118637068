// This is the contact us form. It consists of a form and a submit handler.

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { notify } from 'utilities';
import { colors } from 'utilities/theme';
import { Input, Form, Select, Spin } from 'antd';
import 'moment/locale/ar';
import { Button, StyledLink } from 'components/button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { contactUs, contactUsReasons as contactUsReasonsQuery } from 'services';
import { SideFormContainer } from 'utilities';
import { Icon } from '@iconify/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import firebase from 'firebase';
import arEG from 'antd/es/locale/ar_EG';
import enUS from 'antd/es/locale/en_US';

import {
  FacebookLogo,
  InstagramLogo,
  PhoneLogo,
  TwitterLogo,
} from 'components/svgs';
import { isMobile } from 'react-device-detect';
import { FirebaseAnalytics } from 'helperFunctions/FirebaseAnalytics';
import { useAuthDataContext } from 'utilities/auth-context';

const { Option } = Select;
const { TextArea } = Input;

const ContactUsContainer = styled(SideFormContainer)`
  .ant-row {
    margin: 0 !important;
  }

  section .ant-form-item-label {
    font-family: ${props => props.font};
  }

  section .ant-select-arrow {
    ${props => props.end}: 11px;
    ${props => props.start}: auto;
  }

  section .ant-select-selection__placeholder {
    text-align: start;
  }

  section .ant-select-selection-selected-value {
    float: ${props => props.start};
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 35px;
`;

const ContactDetails = styled.div`
  hr {
    color: ${colors.borderColor};
    opacity: 0.4;
    margin-bottom: 16px;
  }

  & > strong,
  p {
    color: ${colors.primaryColor};
  }

  & > strong {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    display: block;
    margin-bottom: 6px;
  }

  & > p {
    font-weight: 300;
    line-height: 16px;
  }

  a:hover {
    color: inherit;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-inline-start: 8px;
    margin-top: 4px;
  }
`;

const ContactDetailsEntries = styled.div`
  display: flex;

  div {
    display: flex;
  }

  div:last-of-type {
    margin-inline-start: auto;
  }
`;
const CallUs = styled.div`
  display: flex;
  flex-direction: ${props => props.lang === 'en' ? 'row' : 'row-reverse'};
  h3 {
    color: #8BADA3;
    margin-left: ${props => props.lang === 'en' ? '8px' : '0'};
  }
`;
const ContactUsForm = ({
  form: { getFieldDecorator, validateFields, getFieldValue },
  location: { search },
}) => {
  const { language } = i18next;
  const { t } = useTranslation('contactUs');

  const history = useHistory();
  const [nameValue , setNameValue] = useState('');
  const [phoneValue , setPhoneValue] = useState('');
  const [reasonValue , setReasonValue] = useState('');
  const [detailsValue , setDetailsValue] = useState('');
  
  const handelNameChange =  e => {
    setNameValue(e.target.value);
  }
  const handelPhoneChange =  e => {
    setPhoneValue(e.target.value);
  }
  const handelReasonChange = (e) => {
    setReasonValue(e);
  }
  const handelDetailChange =  e => {
    setDetailsValue(e.target.value);
  }
  

  const { data } = useQuery(contactUsReasonsQuery);

  const [submitContactUs, { loading: submitting, error }] = useMutation(
    contactUs
  );
  const { customer } = useAuthDataContext();
  const { phone, email } = customer || {
    id: null,
  };
  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        submitContactUs({
          variables: values,
        })
          .then(res => {
            const {
              data: {
                contactUs: { status, message_ar, message },
              },
            } = res;
            if (status) {
              notify('success', language === 'en' ? message : message_ar);
              history.push('/');
            } else {
              notify('error', language === 'en' ? message : message_ar);
            }
          })
          .catch(err => {
            notify('error', err && err.message);
          });
      }
    });
  };

  const start = language === 'en' ? 'left' : 'right';
  const end = language === 'en' ? 'right' : 'left';
  const font = language === 'en' ? 'FreightSans Pro' : 'GE SS Two';

  // useEffect(() => {
  //   firebase.analytics().logEvent('Contact', {
  //     source: `${window.location.href}`,
  //     phoneNumber: `${phone}`,
  //     email: `${email}`,
  //   });
  // }, [])
  return (
    <ContactUsContainer start={start} end={end} font={font}>
      <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
      >
        <title>Contact Us</title>
        <meta name='description' content={t("meta_description")} />
        <link rel="canonical" href="/contact-us" />
      </Helmet>
      <section>
        <h1>{t('header')}</h1>
        <Form onSubmit={e => handleSubmit(e)}>
          <FormGrid locale={language === 'en' ? enUS : arEG}>
            <Form.Item
              style={{
                textAlign: language === 'en' ? 'left' : 'right',
              }}
              label={t('fields.name')}
            >
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: t('validations.nameValidation'),
                  },
                ],
              })(<Input size="large" onChange={handelNameChange} />)}
            </Form.Item>
            <Form.Item
              style={{
                textAlign: language === 'en' ? 'left' : 'right',
              }}
              label={t('fields.phone')}
            >
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: t('validations.phoneValidation'),
                    len: 11,
                    //pattern: new RegExp('^[0]{1}[0-9]{10}$'),
                  },
                ],
              })(<Input className="forced_ltr forced_english" size="large" onChange={handelPhoneChange} />)}
            </Form.Item>
            <Form.Item
              style={{
                textAlign: language === 'en' ? 'left' : 'right',
              }}
              label={t('fields.reason')}
            >
              {getFieldDecorator('reason', {
                rules: [
                  {
                    required: true,
                    message: t('validations.reasonValidation'),
                  },
                ],
              })(
                <Select onChange={handelReasonChange}
                  placeholder={t('placeholders.reasonPlaceholder')}
                  size="large"
                >
                  {data &&
                    data.contactUsReasons &&
                    data.contactUsReasons.map((reason, reasonIndex) => (
                      <Option
                        key={'reason' + reasonIndex}
                        value={reason[`reason_${language}`]}
                      >
                        {reason[`reason_${language}`]}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              style={{
                textAlign: language === 'en' ? 'left' : 'right',
              }}
              label={t('fields.details')}
            >
              {getFieldDecorator('details', {
                rules: [
                  {
                    required: true,
                    message: t('validations.detailsValidation'),
                  },
                ],
              })(
                <TextArea 
                  onChange={handelDetailChange}
                  rows="5"
                  size="large"
                  placeholder={t('placeholders.detailsPlaceholder')}
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button primary disabled={submitting} 
              onClick={()=> {
                  window.webengage.track('contact US form submitted', {
                "Name":`${nameValue}`,
                "Phone Number":`${phoneValue}`,
                "Contact Reason":`${reasonValue}`,
                "Details":`${detailsValue}`,
              });
            }
            }
              >
                {submitting ? <Spin></Spin> : t('buttons.sendButton')}
              </Button>
            </Form.Item>
          </FormGrid>
        </Form>
      </section>
      <ContactDetails>
        <strong>{t('hotline')}</strong>
        <ContactDetailsEntries>
          <div>
            <CallUs lang={language} >
              <div>
                <PhoneLogo color={colors.primaryColor} />
              </div>
              <h3>16128</h3>
            </CallUs>
          </div>

          <div>
            <a href="https://www.facebook.com/bluebuseg/" target="_blank" rel="noopener">
              <FacebookLogo color={colors.primaryColor} />
            </a>
            <a
              href="https://instagram.com/bluebuseg?igshid=5jgn5n0rkilg"
              target="_blank"
              rel="noopener"
            >
              <InstagramLogo color={colors.primaryColor} />
            </a>
            <a
              href={'https://wa.me/' + process.env.REACT_APP_WHATSAPP}
              target={'_blank'}
              style={{ color: '#8BADA3' }} 
              rel="noopener"
            >
              <Icon icon="dashicons:whatsapp" />
            </a>
            <a
              href={'https://www.facebook.com/messages/t/' + process.env.REACT_APP_FB_PAGE_ID}
              target={'_blank'}
              style={{ color: '#8BADA3' }}
              rel="noopener"
            >
              <Icon icon="fe:messanger" />
            </a>
          </div>
        </ContactDetailsEntries>
        <hr></hr>
        <strong>{t('orderPrivateBus')}</strong>
        <p>{t('orderPrivateBusDetails')}</p>
        <StyledLink to="/private-bus" primary="true">
          {t('buttons.privateBusButton')}
        </StyledLink>
        <PhoneLogo />
      </ContactDetails>
    </ContactUsContainer>
  );
};

export default Form.create({})(ContactUsForm);
