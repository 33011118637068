import React from 'react';
import styled from '@emotion/styled/macro';
import { Form} from 'antd';
import { useTranslation } from 'react-i18next';
import step1 from './images/step-1.jpg';
import step2 from './images/step-2.jpg';
import step3 from './images/step-3.jpg';
import step4 from './images/step-4.jpg';
import step5 from './images/step-5.jpg';
import step3_ar from './images/step-3-ar.jpg';
import step4_ar from './images/step-4-ar.jpg';
import step5_ar from './images/step-5-ar.jpg';
import i18next from 'i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Header = styled.div`
  h1 {
    width: fit-content;
    margin: 30px auto;
    color: #8BADA3;
    font-size: 32px;
    font-weight: bold;
  }
`;
const StepsContainer = styled.div`
  width: 380px;
`;
const Step = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: #ffffff;
  margin-bottom: 20px;
  img {
    max-width: 100%;
    width: 320px;
    height: 65vh;
    align-self: center;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    margin-top: 24px;
  }

  }
`;
const TextBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    width: 50px;
  }
  p {
    font-size: 20px;
    margin: 0;
  }
`;

const DeleteAcontForm = () => {
  const { t } = useTranslation('deleteAccount');
  const { language } = i18next;
  return (
    <Container>
      <Header>
        <h1>{t('header')}</h1>
      </Header>
      <StepsContainer>
        <h2 style={{ textAlign: 'center', fontWeight: '700' }}>{t('steps')}</h2>
        <Step lang={language}>
          <img src={language === 'ar' ? step3_ar : step3} alt="Step 4" />
          <TextBox className="step-4">
            <div>1</div>
            <p>{t('step1')}</p>
          </TextBox>
        </Step>
        <Step lang={language}>
          <img src={language === 'ar' ? step4_ar : step4} alt="Step 5" />
          <TextBox className="step-5">
            <div>2</div>
            <p>{t('step2')}</p>
          </TextBox>
        </Step>
        <Step lang={language}>
          <img src={language === 'ar' ? step5_ar : step5} alt="Step 6" />
          <TextBox className="step-6">
            <div>3</div>
            <p>{t('step3')}</p>
          </TextBox>
        </Step>
      </StepsContainer>
    </Container>
  );
};
export default Form.create({})(DeleteAcontForm);
