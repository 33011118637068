export const colors = {
  primaryColor: '#8BADA3',
  secondaryColor: '#8BADA3',
  success: '#0BCA47',
  danger: '#D3072A',
  grey: '#979797',
  borderColor: '#e5eaee',
  greyLighter: '#E8ECEF',
  white: '#fff',
};
