import styled from '@emotion/styled/macro';
import { colors } from 'utilities/theme';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

/*
Functions to set background-color, border-color and color

Main Types of Buttons:
===============================================
primary | inverted | success | danger | clean
===============================================

Partial Modifiers:
=======
small
======
*/

const bgColorChooser = props => {
  if (!props) return;

  if (props.primary) return colors.secondaryColor;
  if (props.success) return colors.success;
  if (props.danger) return colors.danger;
  if (props.second) return  isMobile ? '#8BADA3' :'#ffff';
  if (props.inverted || props.clean) return 'transparent';
};

const borderColorChooser = props => {
  if (!props) return;

  if (props.primary) return colors.secondaryColor;
  if (props.success) return colors.success;
  if (props.danger) return colors.danger;
  if (props.inverted) return colors.primaryColor;
};

const textColorChooser = props => {
  if (!props) return;

  if (props.primary || props.success || props.danger) return colors.white;
  if (props.inverted) return colors.primaryColor;
  if (props.second) return  isMobile ? '#fff' : '#8BADA3';
  if (props.clean) return colors.secondaryColor;
};

const Button = styled.button`
  min-width: ${props => (props.small ? 'auto' : '195px')};
  font-size: ${props => (props.small ? 14 : 16)}px;
  line-height: ${props => (props.small ? 36 : 42)}px;
  padding: ${props => (props.clean ? 0 : '0 30px')};
  border: 1px solid ${borderColorChooser};
  border: ${props => props.clean && 'none'};
  border-radius: 50px;
  cursor: pointer;
  background-color: ${bgColorChooser};
  color: ${textColorChooser};
  display: inline-block;
  text-align: center;

  &:focus {
    outline: none;
  }
`;

/*
React Router <Link> styled using our <Button> styles above
*/

const CustomLink = Button.withComponent(Link);
const StyledLink = styled(CustomLink)`
  &:hover {
    color: inherit;
  }
`;

export { Button, StyledLink };
