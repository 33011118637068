import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import App from './App';
import client from './apollo_client';
import * as serviceWorker from './serviceWorker';
import './index.css';
import ScrollToTop from './utilities/scrolltotop';
import { Helmet } from 'react-helmet';

// import 'antd-v4/dist/antd.css';
import 'antd/dist/antd.css';

// import i18n (needs to be bundled ;))
import './i18n';
import i18next from 'i18next';

const { language } = i18next;
const appName = process.env.REACT_APP_NAME
window.webengage.options('webpush.registerServiceWorker', false);
ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ScrollToTop />
      <Helmet
        htmlAttributes={{
          lang: language,
        }}
      >
        <meta
          name="keywords"
          content={`
        ${appName} محور المشير
        ,
        حجز تذاكر اتوبيسات ${appName}
        ,
        ${appName} القاهرة
        ,
        حجز تذاكر ${appName}
        ,
        أسعار ${appName}
        ,
        ${appName}s
        ,
        ${appName} reservation
        ,
        موقع ${appName}
        ,
        ${appName} prices
        ,
        bule bus
        ,
        ${appName} alexandria
        ,
        مواعيد ${appName} من الاسكندرية الى القاهرة
        ,
        حجز ${appName}
        ,
        اسعار ${appName}
        ,
        بلو بص
        ,
        سوبر جيت العين السخنة
        ,
        ${appName} october
        ,
        رقم ${appName} اكتوبر
        ,
        فئات أتوبيسات جو باص
        ,
        ${appName} شرم الشيخ
        ,
        blue bas
        ,
        ${appName} سوهاج
        ,
        ${appName} حجز تذاكر
        ,
        ${appName} الغردقة
        ,
        ${appName} cairo
        ,
        ${appName} dahab
        ,
        ${appName} اسعار
        ,
        ${appName} حجز
        ,
        ${appName} station
        ,
        ${appName} egypt
        ,
        ${appName} رمسيس
        ,
        رقم ${appName}
        ,
        ${appName} العين السخنة
        ,
        blubus
        ,
        ${appName} اسكندرية
        ,
        ${appName} schedule egypt
        ,
        شركة ${appName}
        ,
        ${appName} اكتوبر
        ,
        ارقام ${appName}
        ,
        ${appName} number
        ,
        ${appName} schedule
        ,
        ${appName} hotline
        ,
        مواعيد ${appName}
        ,
        ${appName} الغردقة
        ,
        Book your next ${appName} trip easily
        ,
        ${appName} مواعيد
        ,
        ${appName} اكتوبر
        ,
        ${appName} hurghada
        ,
        ${appName}
        ,
        ${appName} booking
        ,
        حجز ${appName}
        ,
        ${appName} حجز
        ,
        ${appName} egypt
        ,
        ${appName}
        ,
        ${appName}
        ,
        bus
        ,
        اتوبيس
        `}
        />
      </Helmet>{' '}
      <App />
    </BrowserRouter>{' '}
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
